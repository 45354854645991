import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PricingAndRefunds from "./pages/PricingAndRefunds";
import NotFound from "./pages/NotFound";
// import DevelopmentOffer from "./pages/DevelopmentOffer";

import "./App.css";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/pricing-n-refund" element={<PricingAndRefunds />} />
        {/* <Route path="/phaje-k-paye" element={<DevelopmentOffer />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
