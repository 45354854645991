import React from "react";
import { NavLink } from "react-router-dom";
import { Phone, Mail, MapPin } from "lucide-react";

const Footer = () => {
  const navLinkClass = ({ isActive }) =>
    `transition-colors duration-200 hover:text-white ${
      isActive ? "text-white" : "text-gray-300"
    }`;

  const navigationLinks = [
    { path: "/", label: "Home" },
    { path: "/about", label: "About Us" },
    { path: "/contact", label: "Contact" },
    { path: "/pricing-n-refund", label: "Pricing & Refund" },
    // { path: "/phaje-k-paye", label: "Paye" },
  ];

  const contactInfo = [
    {
      icon: <MapPin size={16} />,
      text: "21 B BOR Society, Johar Town, Lahore, Pakistan - 54000",
    },
    { icon: <Phone size={16} />, text: "+92 (312) 793-9196" },
    { icon: <Mail size={16} />, text: "info@genvorix.com" },
  ];

  return (
    <footer className="bg-gray-800 text-white py-8 mt-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
        <div>
          <h3 className="text-lg font-bold mb-4">Genvorix pvt ltd</h3>
          <p className="text-gray-300">
            Empowering professionals through expert training and consultancy
            services.
          </p>
        </div>

        <div>
          <h3 className="text-lg font-bold mb-4">Quick Links</h3>
          <nav>
            <ul className="space-y-2">
              {navigationLinks.map(({ path, label }) => (
                <li key={path}>
                  <NavLink to={path} className={navLinkClass}>
                    {label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div>
          <h3 className="text-lg font-bold mb-4">Contact Info</h3>
          <div className="space-y-2">
            {contactInfo.map(({ icon, text }, index) => (
              <p key={index} className="flex items-center gap-2 text-gray-300">
                {icon}
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
