import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const PricingCard = ({ title, children }) => (
  <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300">
    <h3 className="text-xl font-bold text-blue-600 mb-4">{title}</h3>
    <div className="text-gray-700 leading-relaxed">{children}</div>
  </div>
);

const PricingAndRefunds = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-50 to-white">
      <NavBar />
      <main className="flex-grow">
        <div className="max-w-4xl mx-auto p-6 space-y-12">
          {/* Hero Section */}
          <section className="text-center py-12">
            <h2 className="text-3xl md:text-4xl font-bold text-blue-600 mb-6">
              Pricing and Refunds
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              At Genvorix, we believe in tailoring our services to meet your
              unique needs. Our transparent pricing and hassle-free refund
              policy ensure your peace of mind.
            </p>
          </section>

          {/* Main Content Cards */}
          <div className="grid md:grid-cols-2 gap-8">
            <PricingCard title="Custom Pricing">
              <div className="space-y-4">
                <p>
                  At Genvorix, we believe in tailoring our services to meet your
                  unique needs. To ensure you receive the best value and
                  customized solutions, our pricing is determined through direct
                  communication with our clients.
                </p>
                <p>
                  Whether through a call, email, or meeting, we will discuss
                  your requirements and agree upon a fair price that aligns with
                  the scope of the project or training services.
                </p>
              </div>
            </PricingCard>

            <PricingCard title="Pricing Approach">
              <p>
                Our pricing is transparent and flexible, based on the specific
                needs of each client. Once we finalize the details of your
                requirements, we will provide you with a clear and agreed-upon
                price. This approach ensures you get the most value for your
                investment in our training and consulting services.
              </p>
            </PricingCard>

            <PricingCard title="Refund Policy">
              <p>
                We understand that circumstances can change. That's why we have
                a{" "}
                <span className="font-semibold bg-blue-50 px-2 py-1 rounded">
                  no-questions-asked refund policy
                </span>
                . If for any reason you are not satisfied or need to request a
                refund, we will process it promptly without hesitation.
              </p>
            </PricingCard>

            <PricingCard title="Our Commitment">
              <p>
                Your satisfaction and trust are our top priorities, and we are
                here to ensure your experience with Genvorix is seamless and
                risk-free. If you have any questions or need further
                information, please feel free to reach out to us at any time.
              </p>
            </PricingCard>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default PricingAndRefunds;
